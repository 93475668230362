<template>
  <div class="challenges">
    <div class="filter">
      <div class="container">
        <ul class="text-white hidden-sm-and-down">
          <li
            v-for="(tab) in tabs"
            :id="`activate-${tab.id}`"
            :key="tab.id"
            :class="{ active : isActive == tab.id }"
            @click="activate(tab.id)"
          >
            {{ tab.title }}
          </li>
          <span
            v-loading="campaignsIsLoading"
            class="tab-loader"
          ></span>
        </ul>
        <div class="mobile-filter hidden-md-and-up">
          <el-select v-model="isActive">
            <el-option
              v-for="(tab) in tabs"
              :key="tab.id"
              :label="tab.title"
              :value="tab.id"
              @click="activate(tab.id)"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="top-header">
      <div class="container-fluid">
        <div class="container">
          <el-row>
            <el-col
              :xl="6"
              :lg="7"
              :md="24"
            >
              <h2>Individual challenges</h2>
            </el-col>
            <el-col
              :xl="12"
              :lg="12"
              :md="24"
              class="top-header-info"
            >
              <span>Download the app to take part in the challenges</span>
            </el-col>
            <el-col
              :xl="6"
              :lg="5"
              :md="24"
              class="apps"
            >
              <a
                target="_blank"
                :href="brand.appStoreLink"
                style="margin-right: 15px"
              >
                <vsvg-icon
                  data="@assetsIcon/app-store-icon.svg"
                  scale="1"
                  original
                />
              </a>
              <a
                target="_blank"
                :href="brand.playStoreLink"
              >
                <vsvg-icon
                  data="@assetsIcon/play-market-icon.svg"
                  scale="1"
                  original
                />
              </a>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="challenges-content">
      <div class="container">
        <div v-if="challenges.length">
          <div
            v-for="challenge in challenges"
            :key="challenge.id"
            class="card"
          >
            <el-row>
              <el-col
                :xl="8"
                :lg="8"
                :md="24"
                class="challenge-img"
              >
                <img
                  :src="challenge.cover_media_file.large_thumb"
                  alt=""
                  width="90%"
                />
              </el-col>
              <el-col
                :xl="16"
                :lg="16"
                :md="24"
                class="info"
              >
                <el-col
                  :xl="24"
                  :lg="24"
                  :md="24"
                  class="left-side"
                >
                  <el-col
                    :xl="24"
                    :lg="24"
                    :md="24"
                    class="challenge-title"
                  >
                    <h2>{{ challenge.title }}</h2>
                  </el-col>
                  <!--Description and End Date-->
                  <el-col
                    :xl="24"
                    :lg="24"
                    :md="24"
                    class="desc"
                  >
                    <el-col
                      :xl="14"
                      :lg="14"
                      :md="14"
                      :sm="24"
                    >
                      <span class="description">{{ challenge.description }}</span>
                    </el-col>
                    <el-col
                      :xl="10"
                      :lg="10"
                      :md="10"
                      :sm="24"
                      class="right-side"
                    >
                      <el-col
                        :xl="24"
                        :lg="24"
                        class="date-end"
                      >
                        <span>Ends  {{ challenge.end_time_date }}</span>
                      </el-col>
                    </el-col>
                  </el-col>
                  <!--Progress bar-->
                  <el-col
                    v-if="challenge.target_type != 3"
                    :xl="24"
                    :lg="24"
                    :md="24"
                    :sm="24"
                    class="challenges-content-progress"
                  >
                    <el-progress
                      :text-inside="false"
                      :show-text="false"
                      :stroke-width="18"
                      :percentage="percentageNumber(challenge.target, challenge.user.logged_by_all)"
                      color="rgba(221, 0, 29, 1)"
                    ></el-progress>
                  </el-col>
                  <!--Start and Finish-->
                  <el-col
                    v-if="challenge.target_type != 3"
                    :xl="24"
                    :lg="24"
                    :md="24"
                    :sm="24"
                    :xs="24"
                    class="start-finish"
                  >
                    <el-col
                      :xl="12"
                      :lg="12"
                      :md="12"
                      :sm="12"
                      :xs="12"
                      class="start-num"
                    >
                      <p>0</p>
                    </el-col>
                    <el-col
                      :xl="12"
                      :lg="12"
                      :md="12"
                      :sm="12"
                      :xs="12"
                      class="finish-num"
                    >
                      <p>{{ challenge.target }}</p>
                    </el-col>
                  </el-col>
                  <!--Miles and View Details Btn-->
                  <el-col
                    :xl="24"
                    :lg="24"
                    :md="24"
                    :xs="24"
                    class="miles-details"
                  >
                    <el-col
                      :xl="12"
                      :md="12"
                      :xs="24"
                      class="miles"
                    >
                      <p :class="[challenge.target_type === 1 ? 'milesTxt' : challenge.target_type === 3 ? 'pointsTxt' : 'activityTxt']">
                        {{ challenge.user.logged_by_all }}
                      </p>
                    </el-col>
                    <el-col
                      :xl="12"
                      :lg="12"
                      :md="12"
                      :sm="24"
                      :xs="24"
                      class="view-details"
                    >
                      <router-link :to="{ name: 'challenge-challenge-details', params: { id: challenge.id } }">
                        <button class="fgh-button fgh-button--primary">
                          View details
                        </button>
                      </router-link>
                    </el-col>
                  </el-col>
                </el-col>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-else-if="!challenges.length && !loading">
          <p class="text-center">
            No team challenges currently available.
          </p>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="loading"
        v-loading="loading"
        class="container"
        style="min-height: 90px"
      ></div>
    </transition>
  </div>
</template>

<script>
import challengeApi from '@/modules/challenge/services/challenge.api'

export default {
  data: () => ({
    challenges: [],
    loading: true,
    tabs: [
      {
        id: 1,
        title: 'Team challenges',
        tags: [],
        search: '',
        order: '',
        page: 1,
        perPage: 12,
        totalPages: 0,
        items: []
      }
    ],
    campaignsIsLoading: false,
    activeTab: null,
    isActive: 1,
    staticTabCount: 1,
  }),

  computed: {
    hasCampaigns() {
      return this.tabs.length > 1
    }
  },

  created() {
    this.loading = true
    challengeApi
      .challenge
      .index({
        entry_type: 2,
        joined: true,
      })
      .then((response) => {
        this.loading = false
        this.challenges = response.data.data
      });
  },

  methods: {
    activate(el) {
      this.isActive = el
    },
    percentageNumber(percentage, number) {
      if (number == 0) {
        return 0
      }

      return number / percentage * 100
    },
  }
}
</script>

<style lang="scss">
.challenges {
    .filter {
    height: 55px;
    background-color: $feed-banner-primary-bg-color;
    ul {
      list-style: none;
      margin: 0;
      padding-left: 0;
      padding-top: 11.5px;
      li {
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        padding: 7.5px 33px;
        border-radius: 16.5px;
        margin-right: 12px;
        cursor: pointer;
        transition: .3s ease-in;
        @media (max-width: 1200px) {
          padding: 7.5px 22px;
        }
        @media (max-width: 991px) {
          padding: 7.5px 10px;
          font-size: 12px;
        }
      }
      .active {
        background-color: $feed-banner-primary-bg-active-color;
        color: $feed-banner-primary-fg-active-color;
      }
      .tab-loader {
          min-width: 50px;
          .el-loading-mask .circular circle {
              stroke: $white-color;
          }
          .el-loading-spinner {
              margin-top: -15px;
              .circular {
                  width: 32px;
                  height: 32px;
              }
          }
      }
    }
    .mobile-filter {
      .el-select {
        padding-top: 7px;
        color: $white-color;
        cursor: pointer;
        display: block;
        input {
          background: $white-color;
          color: $primary-color;
          border: none;
          border-radius: 4px;
          padding: 12px;
          height: auto;
          line-height: normal;
          font-size: 13px;
          font-weight: bold;
        }
        i {
          margin-left: 90px;
          color: $primary-color;
          font-weight: 900;
          @media (max-width: 416px) {
            margin-left: 0;
            float: right;
          }
        }
      }
    }
  }
  .top-header {
    background-color: $white-color;
    padding: 17px 0;
    @media (max-width: 1024px) {
      .top-header-info {
        text-align: center;
      }
    }
    @media (max-width: 768px) {
      text-align: center;
      .el-col {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
      h2 {
      margin: 0;
    }
    .el-row {
      display: flex;
      align-items: center;
      @media (max-width: 1024px) {
        display: block;
      }
      span {
        font-size: 16px;
      }
      .apps {
        text-align: right;
        @media (max-width: 768px) {
          text-align: center;
        }
      }
    }
  }
  .challenges-content {
    margin-top: 30px;
    margin-bottom: 30px;
    .card {
      background-color: $white-color;
      padding: 19px 18px;
      border-radius: 4px;
      border: none;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .challenge-img {
        //width: 327px;
        @media (max-width: 768px) {
          display: table;
          height: auto;
        }
        img {
          //width: 100%;
          height: 225px;
          @media (max-width: 991px) {
            width: 100%;
            max-height: 100%;
            margin-bottom: 20px;
          }
          @media (max-width: 768px) {
            display: table-row;
          }
        }
      }
      .challenge-title {
        @media (max-width: 425px) {
          text-align: center
        }
      }
      .info {
        //padding-left: 25px;
        .left-side {
          padding: 0 0 10px 0;
          h2 {
            margin: 0;
          }
          .desc {
            margin-top: 6px;
            font-size: 13px;
            .description {
              display: -webkit-box;
              max-width: 100%;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .date-end {
              text-align: right;
              font-size: 14px;
            }
          }
          .challenges-content-progress {
            background-color: rgba(151, 151, 151, 0.18);
            padding: 7px;
            border-radius: 4px;
            margin-top: 28px;
            .el-progress-bar__outer {
              background-color: rgba(151, 151, 151, 0);
              height: 30px !important;
              border-radius: 3px;
              .el-progress-bar__inner {
                border-radius: 3px;
              }
            }
          }
          .start-finish {
            margin-top: 10px;
            p {
              margin: 0;
              font-size: 20px;
              position: relative;
              display: inline-block;
              &:before {
                content: "start";
                position: absolute;
                color: $body-text-color;
                right: -36px;
                top: 6px;
                font-size: 12px;
                font-weight: bold;
              }
            }
            .start-num {
              position: relative;
              text-align: left;
              color: $primary-color;
            }
            .finish-num {
              position: relative;
              text-align: right;
              color: $primary-color;
              p {
                &:before {
                  content: "finish";
                  position: absolute;
                  left: -38px;
                  top: 6px;
                  width: 100%;
                  font-size: 12px;
                  color: $body-text-color;
                  text-align: left;
                }
              }
            }
          }
          .miles-details {
            margin-top: 12px;
            display: flex;
            align-items: center;
            @media (max-width: 425px) {
              display: block;
              text-align: center;
            }
            p {
              margin: 0;
            }
            .miles {
              font-size: 23px;
              p {
                position: relative;
                display: inline-block;
                color: $primary-color;
                @media (max-width: 425px) {
                  margin-left: -77px;
                }
                &.milesTxt {
                  &:before {
                    content: 'miles logged';
                    color: $body-text-color;
                    font-weight: bold;
                    position: absolute;
                    right: -80px;
                    bottom: 4px;
                    font-size: 12px;
                  }
                }
                &.activityTxt {
                  &:before {
                    content: 'activites logged';
                    color: $body-text-color;
                    font-weight: bold;
                    position: absolute;
                    right: -94px;
                    bottom: 4px;
                    font-size: 12px;
                  }
                }
                &.pointsTxt {
                  &:before {
                    content: 'points';
                    position: absolute;
                    right: -50px;
                    bottom: 4px;
                    font-size: 12px;
                  }
                }
              }
            }
            .view-details {
              text-align: right;
              @media (max-width: 425px) {
                text-align: center;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
